<template>
  <div class="card__box">
    <div class="card__wrapper" :class="{'card__wrapper--active': isOurs}">
      <div class="card__top">
        <div class="card__row">
          <div class="card__price">{{ renter.maxPrice }} ₴</div>
          <div class="card__cell">
            <div class="card__button button__icon button__icon--border" @click.stop.prevent="favorites">
              <i class="i-bookmark" :class="{'i-bookmark--blue': renter.isSaved}"></i>
            </div>

            <div
              v-if="user.role === 4 || user.id === renter.responsibleID || renter.isResponsibleBlocked"
              class="card__button button__icon button__icon--border"
              @click.stop.prevent
            >
              <Multiselect
                class="mSelect-dropdown"
                :options="list"
                :searchable="false"
                :show-labels="false"
                :reset-after="true"
                :value="item"
                placeholder=""
                open-direction="bottom"
                @select="actions"
              />
            </div>
            <div v-if="selectionActive" class="card__button button__icon button__icon--border" @click.stop.prevent="select">
              <i :class="{'ri-checkbox-fill': renter.isSelected, 'ri-checkbox-blank-line': !renter.isSelected}"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="card__middle">
        <div v-if="!showSettlements" class="card__field card__field--dark">
          <div class="card__district">
            <i class="i-location i-location--blue"></i>
            <span class="card__span">{{ settlements }}</span>
          </div>
        </div>
        <div v-if="districtsName" class="card__field card__field--dark">
          <div class="card__districts">
            <span class="card__span">{{ districtsName }}</span>
          </div>
        </div>
        <div class="card__row card__tags">
          <div :class="{
            'card__tag card__tag--green': renter.relevance === localConstants.RENT_RELEVANCE.RELEVANT,
            'card__tag card__tag--yellow': renter.relevance === localConstants.RENT_RELEVANCE.RENTED,
            }">
            <span class="card__span">{{ renter.relevanceName }}</span>
            <span v-if="renter.relevance === localConstants.RENT_RELEVANCE.RENTED"> до {{ renter.rentedTill | date }}</span>
          </div>
        </div>
      </div>
      <div class="card__bottom">
        <div class="card__id">ID {{ renter.id }}</div>
        <Relation v-for="(rel, i) in renter.relations" :relation="rel" type="card-tile" :key="i"/>
        <div class="card__agent" :class="{'card__agent--blocked': renter.isResponsibleBlocked}">
          <i class="i-employees card__agent-icon"></i>
          <span class="card__agent-text">{{ renter.responsibleName }}</span>
        </div>
      </div>
      <div class="card__fields">
        <div v-if="type === 'house'" class="card__field">
          <div class="card__text">Розташування</div>
          <div class="card__text">{{ renter.range }}</div>
        </div>
        <div class="card__field">
          <div class="card__text">К-сть кімнат</div>
          <div class="card__text">{{ renter.noOfRooms }}</div>
        </div>
        <div v-if="type === 'house'" class="card__field">
          <div class="card__text">Вид/Тип</div>
          <div class="card__text">{{ material }}/{{ buildingType }}</div>
        </div>
        <div v-if="type === 'apartment'" class="card__field">
          <div class="card__text">Вид</div>
          <div class="card__text">
            {{ material }}
          </div>
        </div>
        <div v-if="type !== 'house'" class="card__field">
          <div class="card__text">
            <span v-if="type === 'apartment'">Тип</span>
            <span v-else>Призначення</span>
          </div>
          <div class="card__text">
            {{ buildingType }}
          </div>
        </div>
        <div v-if="type !== 'apartment'" class="card__field">
          <div class="card__text">Площа м²</div>
          <div v-if="type === 'house'" class="card__text">{{ renter.squareLand }}/{{ renter.squareTotal }}</div>
          <div v-else class="card__text">{{ renter.squareTotal }}</div>
        </div>
        <div v-if="type === 'apartment'" class="card__field">
          <div class="card__text">Поверх</div>
          <div class="card__text">{{ floor }}</div>
        </div>
        <div class="card__field">
          <div v-if="type !== 'house'" class="card__text">Ремонт/Авт. опал.</div>
          <div v-else class="card__text">Ремонт/Гараж</div>
          <div class="card__text">
            <span class="card__span" v-if="renter.isRenovated">Є</span>
            <span class="card__span" v-else>Немає</span>
            <span class="card__span">/</span>
            <span v-if="type === 'house'">
              <span class="card__span" v-if="renter.isGarage">Є</span>
              <span class="card__span" v-else>Немає</span>
            </span>
            <span v-else>
              <span class="card__span">{{ renter.autonomousHeatType }}</span>
            </span>
          </div>
        </div>
        <div class="card__field">
          <div class="card__text">Останній Дзвінок</div>
          <div class="card__text">
            <i class="i-calendar"></i>
            <span v-if="renter.lastCallAt">{{ moment(renter.lastCallAt).format('DD.MM.YYYY') }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <div class="card__field">
          <div class="card__text">Зміна ціни</div>
          <div class="card__text">
            <i class="i-calendar"></i>
            <span class="card__span">{{ moment(renter.updatedAt).format('DD.MM.YYYY') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Archive from '@/components/ModalArchive'
import Relation from '@/components/Relation'
import Edit from '../Upload'
import moment from 'moment'
import {mapGetters} from 'vuex'
import localConstants from '@/utils/localConstants'

export default {
  name: 'CardRenterApartment',
  props: ['renter'],
  components: {
    Multiselect,
    Archive,
    Relation,
  },
  data: () => ({
    moment,
    list: ['Редагувати', 'В Архів'],
    localConstants,
    item: '',
    material: null,
    buildingType: null,
    floor: null,
    districtsName: null,
    settlements: null,
    type: null,
    isOurs: null,
    showSettlements: true
  }),
  computed: {
    ...mapGetters(['constants', 'user']),
    selectionActive() {
      switch (this.type) {
        case 'apartment':
          return this.$store.getters['renters/apartments/selectionActive']
        case 'house':
          return this.$store.getters['renters/houses/selectionActive']
        case 'commerce':
          return this.$store.getters['renters/commerce/selectionActive']
        default:
          return false
      }
    }
  },
  created() {
    const type = this.$route.meta.class
    this.type = type
    switch (type) {
      case 'apartment':
        this.floor = this.renter.floor.join(', ')
        this.material = this.renter.material.map(m => this.constants.APARTMENT_MATERIALS.find(mat => mat.id === m)?.name).join(', ')
        this.buildingType = this.renter.buildingType
          .map(type => this.constants.APARTMENT_TYPES.find(t => t.id === type)?.name)
          .join(', ')

        break
      case 'house':
        this.material = this.renter.material.map(m => this.constants.HOUSE_MATERIALS.find(mat => mat.id === m)?.name).join(', ')
        this.buildingType = this.renter.buildingType.map(type => this.constants.HOUSE_TYPES.find(t => t.id === type)?.name).join(', ')

        break
      case 'commerce':
        this.buildingType = this.renter.buildingType
          .map(type => this.constants.COMMERCE_BUILDING_TYPES.find(t => t.id === type)?.name)
          .join(', ')
        break
    }
    this.districtsName = this.renter.districtID.map(d => d.name).join(', ')
    this.settlements = this.renter.settlement.join(', ')
    this.showSettlements = this.renter.settlement.includes('Рівне') && this.renter.settlement.length === 1
  },
  methods: {
    async favorites() {
      const id = this.renter.id
      const isSaved = this.renter.isSaved
      let path = ''
      switch (this.type) {
        case 'apartment':
          path = 'renters/apartments/favorites'
          break
        case 'house':
          path = 'renters/houses/favorites'
          break
        case 'commerce':
          path = 'renters/commerce/favorites'
          break
      }
      try {
        await this.$store.dispatch(path, {id, isSaved})
        this.renter.isSaved = !this.renter.isSaved
      } catch (err) {}
    },
    async select() {
      const id = this.renter.id
      let path = ''
      switch (this.type) {
        case 'apartment':
          path = 'renters/apartments/selectRenter'
          break
        case 'house':
          path = 'renters/houses/selectRenter'
          break
        case 'commerce':
          path = 'renters/commerce/selectRenter'
          break
      }
      this.$store.commit(path, {id, isSelected: !this.renter.isSelected})
    },
    actions(option) {
      if (option === 'Редагувати') {
        this.$modal.display(Edit, {info: this.renter, renter: this.type}, {name: 'UploadRenter', clickToClose: false})
      } else if (option === 'В Архів') {
        this.$modal.display(
          Archive,
          {info: this.renter, entity: 'renters', action: 'archive'},
          {transition: 'none', classes: 'modalArchive', width: '500px', height: '350px', name: 'ModalArchive'}
        )
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import './style.scss';
.card__button {
    .ri-checkbox-fill, .ri-checkbox-blank-line {
      font-size: 20px;
    }
  }
</style>
